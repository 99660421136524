<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>
                        {{ $t('warehouse.report.price_list.self') }}
                    </h1>
                </div>
            </div>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">{{ $t('warehouse.report.price_list.columns.name') }}</th>
                                        <th v-for="(price_type) in price_types" :key="price_type.uuid" class="text-left">{{ price_type.name }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item) in items" :key="item.id">
                                        <td><RouterLink :to="{name: 'nomenclature.edit', params: {uuid: item.uuid}}">{{ item.name }}</RouterLink></td>
                                        <th v-for="(priceType) in price_types" :key="priceType.uuid">
                                            <template v-for="(itemPrice) in item.prices" class="text-left">
                                                <template v-if="itemPrice && priceType.id === itemPrice.type.id">
                                                    {{ itemPrice?.value }}
                                                </template>
                                            </template>
                                        </th>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import {mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import FilterView from "@/plugins/mixins/FilterView";
import ResourceView from "@/plugins/mixins/ResourceView";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import NavMixin from "@/plugins/mixins/Nav";
import Side from "@/components/Warehouse/Side.vue";

export default {
    name: "PriceTypes",
    directives: {
        mask,
    },
    components: {
        Side,
        PageLayout
    },
    mixins: [
        NavMixin
    ],
    data() {
        return {
            loading: false,
            items: [],
            price_types: []
        };
    },

    computed: {
        ...mapGetters(['userBlock', 'lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.fetchReport();
        },

        fetchReport() {
            this.$http.get('admin/nomenclature/report/price_list')
                .then(({ body }) => {
                    this.items = body.data.items;
                    this.price_types = body.data.price_types;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
</script>
